import i18next from 'i18next';
import { ChannelType } from 'models';
import { ColumnSettings } from 'react-tools';

import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';

import { ChannelService } from '../duck/service';

const channelTypeFormatter = (params: ValueFormatterParams) => {
  const service = new ChannelService();
  return service.channelTypeToString(+params.value);
};

export const channelsColumnSettings: Array<ColumnSettings> = [
  {
    name: 'id',
    width: 60,
    filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    name: 'name',
    width: 170,
    sort: { order: 0, direction: 'asc' },
    lockVisibility: true,
    filter: 'agTextColumnFilter',
  },
  {
    name: 'channelType',
    width: 120,
    sort: { order: 0, direction: 'asc' },
    filter: 'agSetColumnFilter',
    valueFormatter: channelTypeFormatter,
    customFloatingFilterParams: {
      valueFormatter: channelTypeFormatter,
    },
  },
  {
    name: 'workgroupId',
    width: 120,
    filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    name: 'workgroupName',
    width: 120,
    filter: 'agTextColumnFilter',
  },
];
