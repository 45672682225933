import i18next from 'i18next';
import { ChannelFormModel, ChannelListItem, ChannelType } from 'models';
import HttpService from 'utils/http';

export interface IChannelService {
  saveChannel(channel: ChannelFormModel): Promise<{ channelId: number; channelVersion: string }>;
  updateChannel(channel: ChannelFormModel): Promise<{ channelId: number; channelVersion: string }>;
  getChannels(workgroupId: number): Promise<ChannelListItem[]>;
  formModelToListItem(formModel: ChannelFormModel): ChannelListItem;
  channelTypeToString(type: number | string): string;
}

export class ChannelService implements IChannelService {
  channelTypeToString(type: string | number): string {
    switch (+type) {
      case ChannelType.Music:
        return i18next.t<string>('channels.type.music');
      case ChannelType.Video:
        return i18next.t<string>('channels.type.video');
      case ChannelType.VOD:
        return i18next.t<string>('channels.type.vod');
      case ChannelType.OnHold:
        return i18next.t<string>('channels.type.onHold');
      case ChannelType.MusicMessages:
        return i18next.t<string>('channels.type.musicMessages');
      default:
        return i18next.t<string>('channels.type.unsupported');
    }
  }

  formModelToListItem(formModel: ChannelFormModel): ChannelListItem {
    return {
      id: formModel.id,
      name: formModel.name,
      channelType: formModel.channelType,
      isExplicit: formModel.isExplicit,
      workgroupId: formModel.workgroupId,
      workgroupName: formModel.workgroupName,
    };
  }

  async saveChannel(
    channel: ChannelFormModel
  ): Promise<{ channelId: number; channelVersion: string }> {
    var response = await HttpService.postWithReturnedVersion<number>(
      'v6/adminApp/channel',
      channel
    );
    return { channelId: response.data, channelVersion: response.version };
  }

  async updateChannel(
    channel: ChannelFormModel
  ): Promise<{ channelId: number; channelVersion: string }> {
    const response = await HttpService.putWithVersion<number>(
      'v6/adminApp/channel',
      { ...channel, rowVersion: undefined },
      channel.rowVersion as string
    );
    return { channelId: response.data, channelVersion: response.version };
  }

  async getChannels(workgroupId: number): Promise<ChannelListItem[]> {
    return HttpService.get<ChannelListItem[]>(`v6/adminApp/channel/list/${workgroupId}`);
  }

  async getChannel(channelId: number): Promise<ChannelFormModel> {
    const result = await HttpService.getWithVersion<ChannelFormModel>(
      `v6/adminApp/channel/${channelId}`
    );
    return {
      ...result.data,
      rowVersion: result.version,
    };
  }
}
