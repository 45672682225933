import { ChannelListItem, ChannelType } from 'models';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonItem, useGridExportEvents } from 'react-tools';

import Add from '@material-ui/icons/Add';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Edit from '@material-ui/icons/Edit';
import MusicIcon from '@material-ui/icons/MusicNote';
import OnDemandIcon from '@material-ui/icons/OndemandVideo';
import OnHoldIcon from '@material-ui/icons/PhonePaused';
import Refresh from '@material-ui/icons/Refresh';
import VideoIcon from '@material-ui/icons/Videocam';

export const useChannelListBladeButtons = (
  fetchChannels: () => void,
  createChannel: (channelType: ChannelType) => void,
  editChannel: (workgroup: ChannelListItem) => void,
  selected: ChannelListItem[],
  loading: boolean,
  gridApi: any
) => {
  const editChannelCallback = useCallback(
    () => editChannel(selected[0] as ChannelListItem),
    [selected]
  );
  const exportFunctions = useGridExportEvents();
  const [t] = useTranslation();

  const buttons = useMemo<ButtonItem[]>(
    () => [
      {
        tooltip: 'channels.refresh',
        icon: () => <Refresh />,
        onClick: fetchChannels,
        disabled: loading,
      },
      {
        tooltip: 'channels.create',
        icon: () => <Add />,
        onClick: createChannel,
        menuItems: [
          {
            label: 'channels.createVideo',
            value: ChannelType.Video,
            icon: <VideoIcon/>
          },
          {
            label: 'channels.createMusic',
            value: ChannelType.Music,
            icon: <MusicIcon/>
          },
          {
            label: 'channels.createVOD',
            value: ChannelType.VOD,
            icon: <OnDemandIcon/>
          },
          {
            label: 'channels.createOnHold',
            value: ChannelType.OnHold,
            icon: <OnHoldIcon/>
          },
        ],
      },
      {
        tooltip: 'channels.edit',
        icon: () => <Edit />,
        disabled:
          selected === undefined ||
          selected.length === 0 ||
          selected.length > 1,
        onClick: editChannelCallback,
      },
      {
        tooltip: 'channels.export',
        icon: () => <CloudDownload />,
        onClick: () => {
          if (exportFunctions && exportFunctions.exportCsv) {
            exportFunctions.exportCsv(`${t('channels.exportFileName').toString()}.csv`);
          }
        },
      },
    ],
    [selected, loading, gridApi]
  );

  return buttons;
};
