import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { thunks } from 'workgroup/duck';
import { WORKGROUP_TREE_NAME } from '../../../constants';
import { TreeActions } from 'react-tools';

export const useWorkgroupTreeActions = () => {
  const dispatch = useDispatch();

  const fetchWorkgroups = useCallback(
    () => dispatch<any>(thunks.fetchWorkgroupsAsync()),
    [dispatch]
  );

  const collapseAll = useCallback(
    () => dispatch(TreeActions.collapseAll(WORKGROUP_TREE_NAME)),
    [dispatch]
  );

  const expandAll = useCallback(
    () => dispatch(TreeActions.expandAll(WORKGROUP_TREE_NAME)),
    [dispatch]
  );

  const searchWorkgroups = useCallback(
    (searchText: string, arrayFilter?: number[]) =>
      dispatch(
        TreeActions.filter(
          WORKGROUP_TREE_NAME,
          searchText,
          arrayFilter ? arrayFilter : []
        )
      ),
    [dispatch]
  );

  const toggleFavorite = useCallback(
    (workgroupId: number, newStatus: boolean) => {
      if (newStatus) {
        dispatch(thunks.setWorkgroupAsFavorite(workgroupId));
      } else {
        dispatch(thunks.removeWorkgroupAsFavorite(workgroupId));
      }
    },
    [dispatch]
  );

  const deleteWorkgroup = useCallback(
    (workgroupId: number) => console.log(workgroupId),
    []
  );

  return {
    fetchWorkgroups,
    collapseAll,
    expandAll,
    searchWorkgroups,
    toggleFavorite,
    deleteWorkgroup
  }
}
