import { createStyles, makeStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    alignedRow: {
        '& > div[role="gridcell"]': {
        display: 'flex',
        alignItems: 'center'
        }
    }
});

export const useStyles = makeStyles(styles);