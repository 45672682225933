import { WorkgroupLocation } from 'models';
import HttpService from 'utils/http';

export interface ILocationService {
  saveLocation(
    location: WorkgroupLocation
  ): Promise<{ siteId: number; siteVersion: string }>;
  updateLocation(location: WorkgroupLocation): Promise<{ siteId: number; siteVersion: string }>;
  getLocations(workgroupId: number): Promise<WorkgroupLocation[]>;
}

export class LocationService implements ILocationService {
  async saveLocation(
    location: WorkgroupLocation
  ): Promise<{ siteId: number; siteVersion: string }> {
    var response = await HttpService.postWithReturnedVersion<number>(
      `v6/adminApp/location/${location.workgroupId}`,
      location
    );
    return { siteId: response.data, siteVersion: response.version };
  }

  async updateLocation(
    location: WorkgroupLocation
  ): Promise<{ siteId: number; siteVersion: string }> {
    const response = await HttpService.putWithVersion<number>(
      `v6/adminApp/location/${location.id}`,
      { ...location, rowVersion: undefined },
      location.rowVersion as string
    );
    return { siteId: response.data, siteVersion: response.version };
  }

  async getLocations(workgroupId: number): Promise<WorkgroupLocation[]> {
    return await HttpService.get<WorkgroupLocation[]>( `v6/adminApp/location/list/${workgroupId}`);
  }

  async getLocation(locationId: number): Promise<WorkgroupLocation> {
    const result = await HttpService.getWithVersion<WorkgroupLocation>(
      `v6/adminApp/location/${locationId}`
    );
    return {
      ...result.data,
      rowVersion: result.version,
    };
  }
}
