import { unmountComponentAtNode } from 'react-dom';
import { IMVisionApp } from 'react-tools';
import { startReact } from 'start-react';

import { createDefaultStore } from './store';

export const MAA: IMVisionApp = {
  open: (rootNodeId: string = 'root') => {
    startReact(rootNodeId, createDefaultStore());
  },
  unmount: (rootNodeId: string) => {
    unmountComponentAtNode(document.getElementById(rootNodeId) as Element);
  },
  homeFn: () => {},
  userId: 2500,
  workgroupId: 6496,
  version: '',
};

export const setupMAA = () => {
  const setGlobalMAA = () => {
    // set AP as a global property
    window.MAA = MAA;
  };

  const onDOMContentLoaded = (event: Event) => {
    // remove the event listener
    document.removeEventListener('DOMContentLoaded', onDOMContentLoaded);

    const mvisionLoaded: boolean =
      typeof window.mvision === 'object' &&
      typeof window.mvision.adminApp === 'object' &&
      window.mvision.adminApp.MAA_REQUIRED;

    if (!mvisionLoaded) {
      // fallback to the "natural" react code
      startReact('root');
    }

    setGlobalMAA();
  };

  /**
   * If the document and the mvision.services namespace are already loaded
   * we set the global AP object...
   */
  if (document.readyState === 'complete') {
    const mvisionLoaded: boolean =
      typeof window.mvision === 'object' &&
      typeof window.mvision.adminApp === 'object' &&
      window.mvision.adminApp.MAA_REQUIRED;

    if (mvisionLoaded) {
      setGlobalMAA();
    }
  } else {
    // ...otherwise we listen for the document to be ready
    document.addEventListener('DOMContentLoaded', onDOMContentLoaded);
  }
};
