import { EntityType } from 'models';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import { ActionTypes, DeviceSettingsState } from './types';

type DeviceSettingState = ActionType<typeof Actions>;

const initialState: DeviceSettingsState = {
  entity: { entityId: 0, entityType: EntityType.Workgroup },
  parentEntity: null,
  entitySettings: [],
  parentEntitySettings: [],
  isFetching: false,

  isSavingSettings: false,
  saved: false,
};

export const deviceSettingsReducer: Reducer<
  DeviceSettingsState,
  DeviceSettingState
> = (state = initialState, action: DeviceSettingState): DeviceSettingsState => {
  switch (action.type) {
    case ActionTypes.FETCH_DEVICE_SETTINGS:
      return {
        ...state,
        isFetching: true,
        saved: false,
      };
    case ActionTypes.FETCH_DEVICE_SETTINGS_FAIL:
      return {
        ...state,
        isFetching: false,
      };
    case ActionTypes.FETCH_DEVICE_SETTINGS_SUCCESS:
      return {
        ...state,
        entity: action.payload.entity,
        parentEntity: action.payload.parentEntity,
        entitySettings: action.payload.entitySettings,
        parentEntitySettings: action.payload.parentSettings,
        isFetching: false,
      };
    case ActionTypes.CLEAR_DEVICE_SETTINGS:
      return {
        ...state,
        entitySettings: [],
        parentEntitySettings: [],
        entity: { entityId: 0, entityType: EntityType.Workgroup },
        parentEntity: null,
      };
    case ActionTypes.SAVE_DEVICE_SETTINGS: {
      return {
        ...state,
        isSavingSettings: true,
      };
    }
    case ActionTypes.SAVE_DEVICE_SETTINGS_FAIL: {
      return {
        ...state,
        isSavingSettings: false,
      };
    }
    case ActionTypes.SAVE_DEVICE_SETTINGS_SUCCESS: {
      let newSettings = [...state.entitySettings];

      if (newSettings.length) {
        newSettings = action.payload.settings.reduce(
          (acc, setting) => {
            const index = acc.findIndex((x) => x.key === setting.key);
            acc[index].value = setting.value;
            acc[index].userId = setting.userId;
            acc[index].username = setting.username;
            acc[index].timestamp = setting.timestamp;
            acc[index].description = setting.description;
            acc[index].origin = setting.origin;
            acc[index].entityId = setting.entityId;
            acc[index].entityType = setting.entityType;
            acc[index].isInherited = setting.isInherited;
            return acc;
          },
          [...newSettings]
        );
      }

      return {
        ...state,
        entitySettings: newSettings,
        isSavingSettings: false,
        saved: true,
      };
    }

    case ActionTypes.CLEAR_SAVED_FLAG: {
      return {
        ...state,
        saved: false,
      };
    }
    default:
      return state;
  }
};
